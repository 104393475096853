@font-face {
  font-family: 'Blogger Sans';
  src: url('../fonts/Blogger Sans/Blogger_Sans-Bold.otf');
}

@font-face {
  font-family: 'Blogger Sans Regular';
  src: url('../fonts/Blogger Sans/Blogger_Sans.otf');
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSans/Gill Sans.otf');
}
