.react-pdf__Document {
    max-width: '320rem';
}

.react-pdf__Page {
    max-width: '320rem';
}

.react-pdf__Page__canvas {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: '0';
    max-width: '320rem';
}
